import * as Sentry from '@sentry/node'
const supressLogging = process.env.NEXT_PUBLIC_SUPPRESS_HOOK_LOGGING === 'true'

export default function logErr(err, msg, display = false) {
  if (msg) {
    console.error(err, msg)
  } else {
    console.error(err)
  }
  Sentry.withScope((scope) => {
    if (msg) {
      // scope.setContext('meta', { msg })
      err.message = msg + ': ' + err.message
    }
    if (!display) scope.setTag('swallowed', true)
    Sentry.captureException(err)
  })
}

export function logMsg(msg, meta) {
  !supressLogging && console.log(msg, meta)
  Sentry.withScope((scope) => {
    scope.setTag('swallowed', true)
    if (meta) scope.setContext('meta', meta)
    Sentry.captureMessage(msg)
  })
}
